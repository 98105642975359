.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
    background-color: rgba(68, 68, 68, 0.9);
  }
  
  .messageContainer {
    text-align: center;
    max-width: 1200px;
    padding: 15px;
  }
  
  .messageContainer h1 {
    font-size: 1em;
    line-height: 1.3;
    font-weight: bold;
    color: #fff;
  }
  
  @media (min-width: 390px) {
    .messageContainer {
        padding: 20px;
      }
      
      .messageContainer h1 {
        font-size: 1.5em;
      }
  }

  @media (min-width: 765px) {
    .messageContainer h1 {
        font-size: 2em;
      }
  }